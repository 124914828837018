import { FiCheckCircle } from "react-icons/fi";
import { BsCalendar, BsPerson } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Form } from "react-bootstrap";
import MultiSelectDropdown from "../components/multiselectDropdown";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import Loader from "../components/loader";
import { useState, useEffect, useContext } from "react";
import { fillJD } from "../api/chatgpt";
import { AppContext } from "../components/appContext";
import AddTemplate from "../components/addTemplate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/createJob.module.css";

function CreateJob(props) {
  const [refresh, setRefresh] = useState(false);
  const [availableApprovers, setAvailableApprovers] = useState([]);
  const [maxBadge, setMaxBadge] = useState(1);
  const [selectedQualification, setSelectedQualification] = useState([]);
  const [availableBadges, setAvailableBadges] = useState([1]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const myContext = useContext(AppContext);
  const [employerId, setEmployerId] = useState(props.company);
  const [showAiLoader, setShowAiLoader] = useState(false);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [template, setTemplate] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [customizations, setCustomizations] = useState({});
  const token = localStorage.getItem("SavedToken");
  const [recruiterOptions, setRecruiterOptions] = useState([]);
  const [approverOptions, setApproverOptions] = useState([]);
  const [job, setJob] = useState({
    title: "",
    type: "",
    openings: "",
    company_description: "",
    description: "",
    preference: "",
    endDate: new Date(),
    priority: "",
    recruiters: [],
    companyName: "",
    companyDetails: "",
    experience: "",
    functionalArea: "",
    industry: "",
    minSalary: "",
    maxSalary: "",
    approvers: [],
    maxApproval: "",
  });

  const [error, setError] = useState({
    title: "",
    type: "",
    company_description: "",
    openings: "",
    description: "",
    priority: "",
    recruiters: "",
    skills: "",
    companyName: "",
    companyDetails: "",
    experience: "",
    qualification: "",
    functionalArea: "",
    industry: "",
    minSalary: "",
    maxSalary: "",
    city: "",
    state: "",
    country: "",
    maxApproval: "",
  });

  const getApprovers = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getApprovers/${employerId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res.data);
      setAvailableApprovers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getApproverOptions = () => {
    try {
      let arr = [];
      for (var i = 0; i < (job.maxApproval || 0); i++) {
        availableApprovers[i].map((approver) => arr.push(approver));
      }
      setApproverOptions(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const getMaxBadge = async () => {
    try {
      console.log(employerId);
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getMaxBadge/${employerId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },

        credentials: "include",
      });
      console.log(res.data);
      setMaxBadge(res.data);
      setAvailableBadges(
        Array.from({ length: res.data }, (_, index) => index + 1)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const selectEmployer = () => {
    let selectedEmployer = employers.find(
      (employer) => employer._id === employerId
    );
    setJob({
      ...job,
      companyName: selectedEmployer?.companyName
        ? selectedEmployer?.companyName
        : "",
    });
  };

  const fillJdWithAI = async (e) => {
    try {
      setDisabled(true);
      setShowAiLoader(true);
      let res = await fillJD(e, role, job, setJob, token, setSelected);
      console.log(job);
      setDisabled(false);
      setShowAiLoader(false);
    } catch (error) {
      console.log(error);
      setShowAiLoader(false);
    }
  };

  const getEmployers = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getEmployersNames`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setEmployers(res.data.employers);
    } catch (err) {
      console.log(err);
    }
  };

  const validate = () => {
    let status = false;
    let err = { title: "", type: "" };
    if (job.title.trim() === "") {
      status = true;
      err.title = "Title can't be empty";
    }
    if (job.type.trim() === "") {
      status = true;
      console.log("error", error);
      err.type = "Type can't be empty";
    }
    if (job.openings.trim() === "") {
      status = true;
      err.openings = "Openings can't be empty";
    } else if (
      isNaN(Number(job.openings)) ||
      !/^(0|[1-9]\d*)$/.test(job.openings)
    ) {
      status = true;
      err.openings = "Openings should be integer only";
    }

    if (job.description.trim() === "") {
      status = true;
      err.description = "Description can't be empty";
    }
    if (job.priority.trim() === "") {
      status = true;
      err.priority = "Priority can't be empty";
    }
    if (selected.length === 0) {
      status = true;
      err.skills = "Skills can't be empty";
    }
    // if (job.recruiters.length === 0) {
    //   status = true;
    //   // console.log(job.recruiters.length);
    //   err.recruiter = "Select atleast one recruiter";
    // }
    if (job.companyName.trim() === "") {
      status = true;
      err.companyName = "Company name can't be empty";
    }
    if (job.company_description.trim() === "") {
      status = true;
      err.company_description = "Company Description can't be empty";
    }

    if (job.experience.trim() === "") {
      status = true;
      err.experience = "Experience can't be empty";
    }
    if (selectedQualification.length === 0) {
      status = true;
      err.qualification = "Qualification can't be empty";
    }
    if (job.functionalArea.trim() === "") {
      status = true;
      err.functionalArea = "Functional Area can't be empty";
    }
    if (job.industry.trim() === "") {
      status = true;
      err.industry = "Industry can't be empty";
    }
    if (job.minSalary.trim() === "") {
      status = true;
      err.minSalary = "Minimum Salary can't be empty";
    } else if (!/^\d+$/.test(job.minSalary)) {
      status = true;
      err.minSalary = "Invalid Minimum Salary value";
    } else if (job.maxSalary !== "") {
      if (isNaN(Number(job.maxSalary))) {
        status = true;
        err.maxSalary = "Invalid  Maximum Salary value";
      } else if (Number(job.maxSalary) < Number(job.minSalary)) {
        status = true;
        err.minSalary = "Minimum salary must be lesser than maximum salary";
      }
    }
    if (selectedCountry === null) {
      status = true;
      err.country = "country can't be empty";
    }
    if (selectedState === null) {
      status = true;
      err.state = "State can't be empty";
    }
    if (selectedCity === null) {
      status = true;
      err.city = "City can't be empty";
    }
    if (job.maxApproval === "") {
      status = true;
      err.maxApproval = "Please select approvals required";
    }

    if (job.approvers.length === 0) {
      status = true;
      err.approver = "Select atleast one approver";
    } else {
      let flag = false;
      for (var i = 0; i < job.maxApproval; i++) {
        flag = true;
        availableApprovers[i].forEach((approver) => {
          if (job.approvers.find((data) => data.value === approver.value)) {
            flag = false;
          }
        });
        if (flag) {
          status = true;
          err.approver = `Please select atleast one approver with badge ${
            i + 1
          }`;
        }
      }
    }

    setError({ ...err });
    return status;
  };

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setJob({ ...job, [name]: value });
    console.log(job, value);
  };

  const [selected, setSelected] = useState([]);
  const selectTemplate = async () => {
    if (selectedTemplate === "") {
      setSelected([]);
      setJob({
        ...job,
        title: "",
        type: "",
        openings: "",
        description: "",
        preference: "",
      });
    } else {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_URL}/getJobTemplatebyId/${selectedTemplate}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: token,
          },
          credentials: "include",
        });
        console.log(res);
        // setTemplate(res.data);
        const { title, description, type, preference, skills } = res.data;
        setJob({ ...job, title, description, type, preference });
        setSelected(skills);
        console.log(job);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getTemplates = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getJobTemplates`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setTemplate(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getRecruiters = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/getRecruitersforjobpost`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
        data: { employerId },
      });
      console.log(res.data, "rec");
      let arr = [];
      res.data.recruiters.map((recruiter) => {
        console.log(recruiter._id);
        if (recruiter.name === "0") {
          arr.push({
            label: `${recruiter.email}`,
            value: recruiter._id,
            disabled: true,
          });
        } else {
          arr.push({
            label: `${recruiter.name}, ${recruiter.email}`,
            value: recruiter._id,
          });
        }
      });
      setRecruiterOptions(arr);
      console.log("arr", arr);
    } catch (err) {
      console.log(err);
    }
  };

  const postData = async (e) => {
    e.preventDefault();
    console.log(job.recruiters);
    if (!validate()) {
      setButtonLoading(true);
      const {
        title,
        type,
        company_description,
        openings,
        description,
        preference,
        endDate,
        priority,
        recruiters,
        companyName,
        companyDetails,
        experience,
        functionalArea,
        industry,
        approvers,
        maxApproval,
        minSalary,
        maxSalary,
      } = job;
      let recruiterArray = [];
      recruiters.map((recruiter) => recruiterArray.push(recruiter.value));
      let approverArray = [];
      approvers.map((approver) => approverArray.push(approver.value));
      let qualificationArray = [];
      selectedQualification.map((qualification) =>
        qualificationArray.push(qualification)
      );
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/createJob`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          title,
          type,
          company_description,
          openings,
          description,
          preference,
          endDate,
          priority,
          recruiters: recruiterArray,
          approvers: approverArray,
          skills: selected,
          employerId,
          companyName,
          companyDetails,
          experience,
          qualification: qualificationArray.join(","),
          functionalArea,
          industry,
          minSalary,
          maxSalary,
          maxApproval,
          country: selectedCountry.name,
          state: selectedState.name,
          city: selectedCity.name,
        }),
      });
      const data = await res.json();

      if (res.status === 201) {
        toast.success(" Job Added Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("Successfully Added", data);
        setTimeout(() => {
          navigate("/jobs");
        }, 2000);
      } else {
        toast.error("Error while adding!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data.message);
        setButtonLoading(false);
      }
    }
  };

  const handleNewField = (value) => ({
    label: value,
    value: value.toUpperCase(),
  });

  const getCompanyName = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/companyName`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
        credentials: "include",
      });
      console.log(res);
      setJob({ ...job, ["companyName"]: res.data.company.companyName });
    } catch (err) {
      console.log(err.message);
    }
  };

  const getFields = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getcustomizations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setCustomizations(res.data);
      let arr = [];
      res.data.qualifications?.map((qualification) => {
        arr.push({ label: qualification, value: qualification });
      });
      console.log("hi", customizations);
      setCustomizations({ ...res.data, qualifications: arr });
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCompanyName();
    getFields();
    if (props.role !== "Employer") {
      getEmployers();
    }
  }, []);

  useEffect(() => {
    getApprovers();
    getMaxBadge();
  }, [employerId]);

  useEffect(() => {
    if (job.maxApproval !== "") {
      getApproverOptions();
    }
  }, [job.maxApproval]);

  useEffect(() => {
    setSelectedState(
      State.getStatesOfCountry(selectedCountry?.isoCode).find(
        (data) => data.name === job.state
      )
        ? State.getStatesOfCountry(selectedCountry?.isoCode).find(
            (data) => data.name === job.state
          )
        : null
    );
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(
      City.getCitiesOfState(
        selectedState?.countryCode,
        selectedState?.isoCode
      ).find((data) => data.name === job.city)
        ? City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          ).find((data) => data.name === job.city)
        : null
    );
  }, [selectedState]);

  useEffect(() => {
    getTemplates();
  }, [myContext.user, refresh]);

  useEffect(() => {
    selectTemplate();
  }, [selectedTemplate]);

  useEffect(() => {
    selectEmployer();
    getRecruiters();
  }, [employerId]);

  return (
    <div>
      <div className="mx-4 mt-4 mb-sm-4">
        <Row>
          <Col md="6" className={styles["left-form"]}>
            {props.role === "Employer" ? (
              <div>
                <div className="d-flex justify-content-between">
                  <h2 className={styles["job-detail"]}>Job Detail</h2>

                  {/* <AddTemplate refresh={refresh} setRefresh={setRefresh} /> */}
                </div>
                {/* <Form.Label className={styles["form-label"]}>
                  <span className="ps-1"> Select Template</span>
                </Form.Label>
                <Form.Select
                  name="template"
                  className={styles["form-input"]}
                  placeholder="e.g. 4"
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                >
                  <option value="">Select</option>
                  {template?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select> */}

                <Form.Label className={styles["form-label-required"]}>
                  Company Name
                </Form.Label>
                <Form.Control
                  name="companyName"
                  value={job.companyName}
                  onChange={handleInputs}
                  className={styles["form-input"]}
                />
              </div>
            ) : (
              <div>
                <h2 className={styles["job-detail"]}>Job Detail</h2>

                <Form.Label className={styles["form-label-required"]}>
                  Company Name
                </Form.Label>
                <Form.Select
                  name="companyName"
                  value={employerId}
                  onChange={(e) => {
                    setEmployerId(e.target.value);
                  }}
                  className={styles["form-input"]}
                >
                  <option value="">Select Company</option>
                  {employers.map((employer) => (
                    <option value={employer._id} key={employer.companyName}>
                      {employer.companyName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}
            <Form>
              {error.companyName ? (
                <p style={{ color: "red" }}>{error.companyName} </p>
              ) : null}

              <Form.Label className={styles["form-label"]}>
                Let AI Fill your JD, Create a Job Description for?
              </Form.Label>
              <div className="d-flex justify-content-between">
                <Form.Control
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className={styles["form-ai"]}
                  placeholder="e.g. Software Engineer Trainee"
                />
                <button
                  className={styles["ai-fill"]}
                  onClick={(e) => {
                    e.preventDefault();
                    fillJdWithAI(e);
                  }}
                >
                  Fill
                </button>
              </div>

              {showAiLoader && <Loader />}
              <Form.Label className={styles["form-label-required"]}>
                Company Description
              </Form.Label>
              <Form.Control
                name="company_description"
                value={job.company_description}
                onChange={handleInputs}
                className={styles["form-input"]}
                placeholder="Company Description"
              />
              {error.company_description ? (
                <p style={{ color: "red" }}>{error.company_description} </p>
              ) : null}

              {showAiLoader && <Loader />}
              <Form.Label className={styles["form-label-required"]}>
                Job Title
              </Form.Label>
              <Form.Control
                name="title"
                value={job.title}
                onChange={handleInputs}
                className={styles["form-input"]}
                placeholder="e.g. Software Engineer Trainee"
              />
              {error.title ? (
                <p style={{ color: "red" }}>{error.title} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                Job Description
              </Form.Label>
              <Form.Control
                value={job.description}
                onChange={handleInputs}
                name="description"
                className={styles["form-input"]}
                placeholder="e.g. Key Responsibilities
                1.
                2.
                3."
                as="textarea"
                rows={6}
              />
              {error.description && (
                <p style={{ color: "red" }}>{error.description} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                Skills required
              </Form.Label>
              <MultiSelectDropdown value={{ selected, setSelected }} />
              {error.skills && <p style={{ color: "red" }}>{error.skills} </p>}

              <Form.Label className={styles["form-label-required"]}>
                Job Type
              </Form.Label>
              <Form.Check
                inline
                value="In-Office"
                onChange={handleInputs}
                type={"radio"}
                id={`office`}
                label={`In-office`}
                name={"type"}
                checked={"In-Office" === job.type}
                className={styles["radio"]}
              />
              <Form.Check
                inline
                value="Remote"
                onChange={handleInputs}
                type={"radio"}
                id={`remote`}
                label={`Remote`}
                name={"type"}
                checked={"Remote" === job.type}
                className={styles["radio"]}
              />
              <Form.Check
                inline
                value="Hybrid"
                onChange={handleInputs}
                type={"radio"}
                id={`hybrid`}
                label={`Hybrid`}
                name={"type"}
                checked={"Hybrid" === job.type}
                className={styles["radio"]}
              />
              {error.type ? (
                <p style={{ color: "red" }}>{error.type} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                No. of Openings
              </Form.Label>
              <Form.Control
                value={job.openings}
                onChange={handleInputs}
                name="openings"
                className={styles["form-input"]}
                placeholder="e.g. 4"
              />
              {error.openings && (
                <p style={{ color: "red" }}>{error.openings} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                <span className="ps-1"> Functional Area</span>
              </Form.Label>
              <Form.Select
                name="functionalArea"
                className={styles["form-input"]}
                placeholder="e.g. 4"
                value={job.functionalArea}
                onChange={handleInputs}
              >
                <option value="">Select</option>
                {customizations?.functionalAreas?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {error.functionalArea && (
                <p style={{ color: "red" }}>{error.functionalArea} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                <span className="ps-1">Industry</span>
              </Form.Label>
              <Form.Select
                name="industry"
                className={styles["form-input"]}
                placeholder="e.g. 4"
                value={job.industry}
                onChange={handleInputs}
              >
                <option value="">Select</option>
                {customizations?.industry?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {error.industry && (
                <p style={{ color: "red" }}>{error.industry} </p>
              )}

              <Form.Label className={styles["form-label-required"]}>
                Country
              </Form.Label>
              <Select
                options={Country.getAllCountries()}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedCountry}
                onChange={(item) => {
                  setSelectedCountry(item);
                }}
              />
              {error.country ? (
                <p style={{ color: "red" }}>{error.country} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                State
              </Form.Label>
              <Select
                options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedState}
                onChange={(item) => {
                  setSelectedState(item);
                }}
              />
              {error.state ? (
                <p style={{ color: "red" }}>{error.state} </p>
              ) : null}

              <Form.Label className={styles["form-label-required"]}>
                City
              </Form.Label>
              <Select
                options={City.getCitiesOfState(
                  selectedState?.countryCode,
                  selectedState?.isoCode
                )}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["name"];
                }}
                value={selectedCity}
                onChange={(item) => {
                  setSelectedCity(item);
                }}
              />
              {error.city ? (
                <p style={{ color: "red" }}>{error.city} </p>
              ) : null}
              <Form.Label className={styles["form-label-required"]}>
                Salary Range
              </Form.Label>
              <div className="d-flex justify-content-between align-items-end">
                <Form.Control
                  value={job.minSalary}
                  onChange={handleInputs}
                  name="minSalary"
                  className={styles["form-input"]}
                  placeholder="Minimum"
                />

                <span className="mx-2"> to </span>
                <Form.Control
                  value={job.maxSalary}
                  onChange={handleInputs}
                  name="maxSalary"
                  className={styles["form-input"]}
                  placeholder="Maximum"
                />
              </div>
              {error.minSalary ? (
                <p style={{ color: "red" }}>{error.minSalary} </p>
              ) : null}
              {error.maxSalary && (
                <p style={{ color: "red" }}>{error.maxSalary} </p>
              )}
              <Form.Label className={styles["form-label-required"]}>
                <span className="ps-1">Educational qualification</span>
              </Form.Label>
              {customizations.qualifications && (
                <MultiSelect
                  hasSelectAll={false}
                  options={customizations.qualifications}
                  value={selectedQualification}
                  onChange={setSelectedQualification}
                  labelledBy="Select"
                  isCreatable={true}
                  onCreateOption={handleNewField}
                />
              )}
              {error.qualification && (
                <p style={{ color: "red" }}>{error.qualification} </p>
              )}
              <Form.Label className={styles["form-label-required"]}>
                Required Experience
              </Form.Label>
              <Form.Control
                value={job.experience}
                onChange={handleInputs}
                name="experience"
                className={styles["form-input"]}
                placeholder="e.g. 4 years"
              />
              {error.experience && (
                <p style={{ color: "red" }}>{error.experience} </p>
              )}
              <Form.Label className={styles["form-label"]}>
                Do you have any candidate preference?{" "}
                <span style={{ color: "silver", fontWeight: "10" }}>
                  (Optional)
                </span>
              </Form.Label>
              <Form.Control
                value={job.preference}
                onChange={handleInputs}
                name="preference"
                className={styles["form-input"]}
                placeholder="e.g. Only Computer Science graduate preferred"
                rows={2}
                as="textarea"
              />
            </Form>
          </Col>
          <Col md="6" className={styles["right"]}>
            <Form>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <BsPerson />
                  <span className="ps-1"> Recruiters Assigned</span>
                </Form.Label>
                <div>
                  {/* <pre>
                    {job.recruiters?.map((recruiter) => (
                      <p className={styles["recruiter"]}>{recruiter.label}</p>
                    ))}
                  </pre> */}
                  <MultiSelect
                    options={recruiterOptions}
                    value={job.recruiters}
                    onChange={(value) => {
                      console.log("a", value);
                      setJob({
                        ...job,
                        ["recruiters"]: value,
                      });
                    }}
                    labelledBy="Select"
                    overrideStrings={{
                      allItemsAreSelected: "All recruiters are selected",
                    }}
                  />
                </div>
                {error.recruiter && (
                  <p style={{ color: "red" }}>{error.recruiter} </p>
                )}
              </div>

              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <FiCheckCircle />
                  <span className="ps-1"> Approvals Required</span>
                </Form.Label>
                <Form.Select
                  name="maxApproval"
                  className={styles["form-input"]}
                  placeholder="e.g. 4"
                  value={job.maxApproval}
                  onChange={handleInputs}
                >
                  <option value="">Select</option>
                  {availableBadges.map((badge) => (
                    <option value={badge}>{badge}</option>
                  ))}
                </Form.Select>
                {error.maxApproval && (
                  <p style={{ color: "red" }}>{error.maxApproval} </p>
                )}
              </div>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <BsPerson />
                  <span className="ps-1"> Approvers Assigned</span>
                </Form.Label>
                <div>
                  {/* <pre>
                    {job.approvers?.map((approver) => (
                      <p className={styles["approver"]}>{approver.label}</p>
                    ))}
                  </pre> */}
                  <MultiSelect
                    options={approverOptions}
                    value={job.approvers}
                    onChange={(value) => {
                      console.log("a", value);
                      setJob({
                        ...job,
                        ["approvers"]: value,
                      });
                    }}
                    labelledBy="Select"
                    overrideStrings={{
                      allItemsAreSelected: "All approvers are selected",
                    }}
                  />
                </div>
                {error.approver && (
                  <p style={{ color: "red" }}>{error.approver} </p>
                )}
              </div>

              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <BsCalendar />
                  <span className="ps-1"> Expected Date of Joining </span>
                </Form.Label>
                <div className="date-picker-container">
                  <DatePicker
                    showIcon
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    selected={job.endDate}
                    onChange={(date) => {
                      setJob({ ...job, ["endDate"]: date });
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className={styles["right-form"]}>
                <Form.Label className={styles["form-label-required"]}>
                  <FiCheckCircle />
                  <span className="ps-1"> Priority</span>
                </Form.Label>
                <Form.Select
                  name="priority"
                  className={styles["form-input"]}
                  placeholder="e.g. 4"
                  value={job.priority}
                  onChange={handleInputs}
                >
                  <option value="">Select</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
                {error.priority && (
                  <p style={{ color: "red" }}>{error.priority} </p>
                )}
              </div>
            </Form>
            <div>
              {buttonLoading ? (
                <button className={styles["submit"]} disabled>
                  Submit
                </button>
              ) : (
                <button className={styles["submit"]} onClick={postData}>
                  Submit
                </button>
              )}
              <Link to="/jobs">
                <button className={styles["cancel"]}>Cancel</button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CreateJob;
