import React, { useState } from "react";
import Popup from "reactjs-popup";
import styles from "../styles/createUser.module.css";
import { BiCommentDetail } from "react-icons/bi";
import Image from "./User/image";
import { IoMdSend } from "react-icons/io";
import { TbMessage2 } from "react-icons/tb";

const CommentModal = ({ id, name, comments, onCommentSubmit }) => {
  const m = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [commentContent, setCommentContent] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onCommentSubmit(id, commentContent.trim());
    setCommentContent("");
  };

  // const handleChange = (e) => {
  //   setCommentContent(e.target.value)
  //   const textWithoutWhitespace = e.target.value.replace(/\s/g, '');
  //   setCommentContent(textWithoutWhitespace);
  // }

  // {
  //   data.lenght == 0 {loader}:
  //   ({

  //   })
  // }

  return (
    <Popup
      modal
      // closeOnDocumentClick={false}
      trigger={
        <button className={styles.submitComment}>
          <TbMessage2 color="black" size={25} />
        </button>
      }
    >
      {(close) => (
        <div className={styles["modal"]}>
          <div className={styles.box_container_new}>
            <h3 className={styles.header}>{name} Comments</h3>
            <button className={styles["close"]} onClick={close}>
              &times;
            </button>
          </div>
          <div className="mt-3">
            {comments?.map((comment) => (
              <div className="d-flex" key={comment._id}>
                <div className={styles.image_tank}>
                  <Image
                    image={comment?.user?.image}
                    firstName={comment?.user?.name}
                    size={20}
                  />
                </div>
                <div>
                  <span>
                    <strong>{comment?.user?.name}</strong>
                  </span>
                  <span className="ms-3" style={{ color: "grey" }}>
                    {new Date(comment.createdAt).getDate() +
                      "/" +
                      new Date(comment.createdAt).getMonth() +
                      "/" +
                      new Date(comment.createdAt).getFullYear()}
                  </span>
                  <p className={styles.content}>{comment.content}</p>
                </div>
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="d-flex">
              <textarea
                id="commentContent"
                name="commentContent"
                placeholder="Add Comment"
                value={commentContent}
                rows={1}
                className={styles.comment}
                onChange={(e) => setCommentContent(e.target.value)}
                // onChange={handleChange}
              />
              <button type="submit" className={styles.submitComment}>
                <IoMdSend size={20} color="#2186F2" />
              </button>
            </div>
          </form>
        </div>
      )}
    </Popup>
  );
};

export default CommentModal;
