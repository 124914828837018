import styles from "../../styles/adminDashboard.module.css";
import { BsSearch } from "react-icons/bs";
import { BiBell } from "react-icons/bi";
import { Row, Card, Col } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import SplineChart from "../splineChart";

function AdminDashboard() {
  return (
    <div className={styles["admin"]}>
      <h1 className={`${styles["dash-head"]}`}>Dashboard</h1>
      <div className="d-flex justify-content-between align-items-center">
        <p className={styles["dash-text"]}>User Activity Summary</p>
        <div className="d-flex align-items-center">
          <div className={styles["dash-icons"]}>
            <BsSearch />
          </div>
          <div className={styles["dash-icons"]}>
            <BiBell />
          </div>
        </div>
      </div>
      <Row className="mx-1 me-3 justify-content-between">
        <Card className={`shadow py-2 ${styles["admin-cards"]}`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>65</h3>
            <p className={`${styles["card-right"]}`}>
              7 Days
              <span>
                <BsChevronDown />
              </span>
            </p>
          </div>
          <p className={styles["card-text"]}>Companies Onboarded</p>
          <p className={styles["card-bottom"]}>This Week</p>
        </Card>

        <Card className={`${styles["admin-cards"]} shadow py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>65</h3>
            <p className={`${styles["card-right"]}`}>
              7 Days
              <span>
                <BsChevronDown />
              </span>
            </p>
          </div>
          <p className={styles["card-text"]}>Companies Onboarded</p>
          <p className={styles["card-bottom"]}>This Week</p>
        </Card>
        <Card className={`${styles["admin-cards"]} col-md-2 shadow py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>65</h3>
            <p className={`${styles["card-right"]}`}>
              7 Days
              <span>
                <BsChevronDown />
              </span>
            </p>
          </div>
          <p className={styles["card-text"]}>Companies Onboarded</p>
          <p className={styles["card-bottom"]}>This Week</p>
        </Card>
        <Card className={`${styles["admin-cards"]} col-md-2 shadow py-2`}>
          <div className="d-flex justify-content-between">
            <h3 className={styles["card-head"]}>65</h3>
            <p className={`${styles["card-right"]}`}>
              7 Days
              <span>
                <BsChevronDown />
              </span>
            </p>
          </div>
          <p className={styles["card-text"]}>Companies Onboarded</p>
          <p className={styles["card-bottom"]}>This Week</p>
        </Card>
      </Row>

      {/* Row 2 */}

      <Row className="mx-1 mt-3 justify-content-between">
        <div className={styles["latest-jobs"]}>
          <div>
            <h3 className={styles["jobs-head"]}>Latest active jobs</h3>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="d-flex justify-content-between">
                  <td className={styles["active-jobs-text"]}>
                    Full Stack Developer
                  </td>
                  <td className={styles["active-jobs-numbers"]}>
                    15 Candidates
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td className={styles["active-jobs-text"]}>
                    Full Stack Developer
                  </td>
                  <td className={styles["active-jobs-numbers"]}>
                    15 Candidates
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td className={styles["active-jobs-text"]}>
                    Full Stack Developer
                  </td>
                  <td className={styles["active-jobs-numbers"]}>
                    15 Candidates
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td className={styles["active-jobs-text"]}>
                    Full Stack Developer
                  </td>
                  <td className={styles["active-jobs-numbers"]}>
                    15 Candidates
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td className={styles["active-jobs-text"]}>
                    Full Stack Developer
                  </td>
                  <td className={styles["active-jobs-numbers"]}>
                    15 Candidates
                  </td>
                </tr>
                <tr className="d-flex justify-content-between">
                  <td className={styles["active-jobs-text"]}>
                    Full Stack Developer
                  </td>
                  <td className={styles["active-jobs-numbers"]}>
                    15 Candidates
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles["spline-chart"]}>
          <SplineChart />
        </div>
      </Row>
    </div>
  );
}

export default AdminDashboard;
