import { Col, Row } from "react-bootstrap";
import styles from "./styles.module.css";
import ImageCard from "./imageCard";
import DetailsCard from "./detailsCard";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function Candidate() {
  const { candidateId } = useParams();
  const [candidateJob, setCandidateJob] = useState({});
  const [candidate, setCandidate] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [currentCompany, setCurrentCompany] = useState({});
  const token = localStorage.getItem("SavedToken");
  const getCandidate = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URL}/getCandidatebyParentId/${candidateId}`,
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        console.log(res.data);
        setCandidate({
          ...res.data.candidate,
          parentId: res.data._id,
          comments: res.data.comments,
        });
        let curr = res.data?.candidate?.workExperience.find(
          (company) => company.present === true
        );
        if (curr) {
          setCurrentCompany(curr);
        }
        setCandidateJob(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCandidate();
  }, [candidateId, refresh]);

  return (
    <div>
      <h2 className={styles.head}>Candidate Profile</h2>
      <p className={styles.text}>View the candidate details here</p>
      <Row className="mt-4 mx-3">
        <Col md={4}>
          <ImageCard candidate={candidate} />
        </Col>
        <Col md={7}>
          <DetailsCard
            candidate={candidate}
            title={candidateJob.job?.title}
            status={candidateJob.status}
            company={currentCompany}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Candidate;
