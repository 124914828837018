import { Card } from "react-bootstrap";
import { MdMessage } from "react-icons/md";
import styles from "./styles.module.css";
import { FaUserGraduate } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import { AiFillHome } from "react-icons/ai";
import CommentModal from "../comments";
import { postCandidateComment } from "../../api/jobs";

function DetailsCard(props) {
  console.log("ye rehe", props.candidate);
  const handleCommentSubmit = async (candidateId, content) => {
    try {
      await postCandidateComment(candidateId, content);
      // Update the selected job's comments array with the new comment
      props.setRefresh(!props.refresh);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card className="p-3 py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className={styles.name}>{props.candidate?.name}</h2>
        <CommentModal
          name={props.candidate?.name}
          id={props.candidate?.parentId}
          comments={props.candidate?.comments}
          onCommentSubmit={handleCommentSubmit}
        />
      </div>
      <div className="d-flex justify-content-between mt-5 align-items-center">
        <p className={styles.details}>
          <FaUserGraduate color="grey" /> {props.candidate?.experience} years
        </p>
        <p className={styles.details}>
          <BiRupee color="grey" /> {props.candidate?.desiredJobexpectedCTC}
        </p>
        <p className={styles.details}>
          <AiFillHome color="grey" /> {props.candidate?.city}
        </p>
      </div>
      <p className={styles.current}>
        <strong> Current:</strong>{" "}
        {props.company?.company ? props.company?.company : "None"}
      </p>
      <p className={styles.current}>
        <strong> Key Skills:</strong>{" "}
        {props.candidate.skills?.map((skill) => (
          <span className={styles.skillbox}> {skill}</span>
        ))}
      </p>
      <span className={styles.status}>{props.status}</span>
      {/* TO BE COMPLETED */}
    </Card>
  );
}

export default DetailsCard;
