import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styles from "../styles/createUser.module.css";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateUser(props) {
  console.log(props);
  const availableBadges = Array.from(
    { length: props.maxBadge || 0 },
    (_, index) => index + 1
  );
  console.log(availableBadges);
  const token = localStorage.getItem("SavedToken");
  const [selectedRole, setSelectedRole] = useState({});
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
    badge: "",
  });

  const clearData = () => {
    setUser({
      firstName: "",
      badge: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      role: "",
    });
  };
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
    badge: "",
  });

  const validate = () => {
    let status = false;
    let err = {};

    if (user.firstName.trim() === "") {
      status = true;
      err.firstName = "First name can't be empty";
    } else if (!/^[a-zA-Z]+$/.test(user.firstName)) {
      status = true;
      err.firstName = "Invalid First Name";
    }
    if (!/^[a-zA-Z]+$/.test(user.lastName) && user.lastName !== "") {
      status = true;
      err.lastName = "Last name Invalid";
    }
    if (user.email === "") {
      status = true;
      err.email = "Email can't be empty";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email)
    ) {
      status = true;
      err.email = "Invalid Email";
    }
    if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(user.mobile)) {
      status = true;
      err.mobile = "Invalid Mobile no.";
    }

    if (user.role.trim() === "") {
      status = true;
      err.role = "Role can't be empty";
    }

    if (user.password.trim() === "") {
      status = true;
      err.password = "password can't be empty";
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
        user.password
      )
    ) {
      status = true;
      err.password = "Password must be strong between 6 to 16 characters";
    }
    if (selectedRole?.name === "Approver" && user.badge === "") {
      status = true;
      err.badge = "Please select a badge first";
    }

    setError({ ...err });
    return status;
  };

  const setRole = () => {
    setSelectedRole(props.roles.find((role) => role._id === user.role));
    console.log(selectedRole);
  };

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
    console.log(user, value);
  };
  const postData = async () => {
    const { firstName, lastName, email, mobile, password, role, badge } = user;
    // console.log(user);
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/createEmployee`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: ["Employee", "CompanyAdmin"].includes(selectedRole?.name)
          ? JSON.stringify({
              firstName,
              lastName,
              email,
              mobile,
              badge,
              password,
              role,
            })
          : JSON.stringify({
              firstName,
              lastName,
              email,
              mobile,
              password,
              role,
            }),
      }
    );
    const data = await res.json();
    if (res.status === 201) {
      console.log("Successfully Reggistration", data);
      console.log(props.props.refresh);
      clearData();
      props.props.setRefresh(!props.props.refresh);
      toast.success("Employee Added Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (res.status === 422) {
      toast.error("This Email already exists", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    } else {
      toast.error("error while adding", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data.message);
    }
  };

  useEffect(() => {
    setRole();
  }, [user.role]);

  return (
    <div>
      <Popup
        trigger={
          <button className={styles["add-button"]}> + Add New User</button>
        }
        modal
        nested
      >
        {(close) => (
          <div className={styles["modal"]}>
            <div className={styles.heading_container}>
              <button className={styles["close"]} onClick={close}>
                &times;
              </button>
              <div className={styles["header"]}> Add new user </div>
            </div>
            <div className={styles["popup-content"]}>
              <Form className="row justify-content-center">
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    size="sm"
                    value={user.role}
                    className={styles.input_fields}
                    onChange={handleInputs}
                    name="role"
                  >
                    <option value="">Select Role</option>
                    {props.roles.map((role) => (
                      <option value={role._id}>{role.name}</option>
                    ))}
                  </Form.Select>
                  {error.role ? (
                    <p style={{ color: "red" }}>{error.role} </p>
                  ) : null}
                </Form.Group>
                {["Employee", "CompanyAdmin"].includes(selectedRole?.name) && (
                  <Form.Group className="mb-3">
                    <Form.Label>Badge</Form.Label>
                    <Form.Select
                      size="sm"
                      value={user.badge}
                      onChange={handleInputs}
                      name="badge"
                      className={styles.input_fields}
                    >
                      <option value="">Select Option</option>
                      {availableBadges.map((badge) => (
                        <option value={badge}>{badge}</option>
                      ))}
                    </Form.Select>
                    {error.badge ? (
                      <p style={{ color: "red" }}>{error.badge} </p>
                    ) : null}
                  </Form.Group>
                )}

                <Form.Group className="mb-3 col-md-6">
                  <Form.Label className={styles["label"]}>
                    First name
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    className={styles.input_fields}
                    onChange={handleInputs}
                    placeholder="First Name"
                    name="firstName"
                    value={user.firstName}
                  />
                  {error.firstName ? (
                    <p style={{ color: "red" }}>{error.firstName} </p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    size="sm"
                    value={user.lastName}
                    onChange={handleInputs}
                    placeholder="Last name"
                    name="lastName"
                    className={styles.input_fields}
                  />
                  {error.lastName ? (
                    <p style={{ color: "red" }}>{error.lastName} </p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    size="sm"
                    type="email"
                    value={user.email}
                    onChange={handleInputs}
                    placeholder="Enter email"
                    className={styles.input_fields}
                    name="email"
                  />
                  {error.email ? (
                    <p style={{ color: "red" }}>{error.email} </p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    size="sm"
                    type="mobile"
                    value={user.mobile}
                    onChange={handleInputs}
                    placeholder="Enter mobile no."
                    name="mobile"
                    className={styles.input_fields}
                    maxLength={10}
                  />
                  {error.mobile ? (
                    <p style={{ color: "red" }}>{error.mobile} </p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    value={user.password}
                    onChange={handleInputs}
                    placeholder="Password"
                    className={styles.input_fields}
                    name="password"
                  />
                  {error.password ? (
                    <p style={{ color: "red" }}>{error.password} </p>
                  ) : null}
                </Form.Group>
                <div className="mb-0 d-flex justify-content-center">
                  <Button
                    className={styles["cancel-button"]}
                    onClick={(e) => {
                      clearData();
                      close();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className={styles["login-button"]}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!validate()) {
                        postData();
                        setTimeout(() => {
                          close();
                        }, 2000);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default CreateUser;
